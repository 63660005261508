import React, {FunctionComponent} from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import Subheader from "../components/subheader";
import { Author } from "../utils/models";
import {Card} from "../components/card";
import slugify from "slugify";
import {Grid, AvatarImage} from "../components/common";
import Img from "gatsby-image";
import styled from "styled-components";
import SEO from "../components/seo";
import { toUrl } from 'gatsby-source-gravatar'

interface AuthorsPageProps {
  data: {
    authors: {
      edges: Array<{ node: Author }>;
    };
  };
  location: Location;
}

const TagName = styled.p`
  margin: 0 !important;
`;

const AuthorsPage: FunctionComponent<AuthorsPageProps> = ({data, location}) => {
  const authors = data.authors.edges.map(node => node.node);
  
  return (
    <Layout bigHeader={false}>
      <SEO
        location={location}
        title={`Authors`}
        type={`Series`}
      />
      <Subheader title={`Authors`} subtitle={`${authors.length} authors`}/>
      <Grid columns={4}>
        {authors.map((author, index) => {
          const avatarUrl = React.useMemo(() => toUrl(author.email, 'size=128'), [author.email]);
          return (
            <Card
              key={index}
              path={`/author/${slugify(author.username, {lower: true})}`}
              compact={true}
              style={{textAlign: 'center'}}
            >
              {/* gatsby-image doesn't handle SVGs, hence we need to take care of it */}
              {/* {author.icon.extension !== 'svg'
                ? <Img fixed={author.icon.childImageSharp.fixed}/>
                : <TagSvgIcon src={author.icon.publicURL} alt={author.displayname}/>
              } */}
              <AvatarImage src={avatarUrl} />
              <TagName>
                {author.displayname}
              </TagName>
            </Card>
          );
        })}
      </Grid>
    </Layout>
  );
};

export default AuthorsPage;

export const query = graphql`
  query {
  authors: allAuthorsYaml {
      edges {
        node {
          email
          username
          displayname
        }
      }
    }
  }
`;
